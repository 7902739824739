import { Box, Link } from '@mui/material'
import React from 'react'
import Build from './build'
import Footer from './footer'
import Services from './services'

export default function Homepage() {

    const servicesList = [
        "Use a scrutinized Academic Identity",
        "Build a full featured exclusive Classroom",
        "Or setup just a shared cloud storage"
    ]
    const reasonsList = [
        "Privacy Focused Implementation",
        "Industry Standard Practices",
    ]

    return <Box>
        <Build />
        <Services
            bgColor='primary.main'
            textColor='white'
            headerText='Several services to explore from.'
            displayList={servicesList}
            underText={
                <>
                    We are building services for every use cases.
                    <br />
                    Explore&nbsp;
                    <Link
                        href="https://docs.build.withmazeyard.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            color: 'white',
                            textDecoration: ' underline'
                        }}
                    >
                        Documentation
                    </Link>
                    &nbsp;to know more.
                </>
            }
        />
        <Services
            bgColor='white'
            textColor='text.primary'
            headerText='Reasons for building with us.'
            displayList={reasonsList}
            underText={
                <Link
                    href="https://console.build.withmazeyard.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Get Started
                </Link>
            }
        />
        <Footer />
    </Box>
}
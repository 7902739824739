import React from "react"
import { Box, Divider, Grid, Typography } from '@mui/material'

export default function Slight() {

    return <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
    >
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    marginBottom: '40px',
                    marginRight: '-15px',
                    width: '100%',
                    maxWidth: '200px',
                    fontSize: '2.0rem',
                }}
            >
                Build from Scratch
            </Typography>
            <Divider
                orientation="vertical"
                flexItem
                sx={{
                    transform: 'rotate(30deg)',
                    borderLeft: '1px solid #000',
                    height: '170px',
                    marginLeft: '10px',
                    marginRight: '10px',
                }}
            />
            <Typography
                variant="h4"
                sx={{
                    marginTop: '40px',
                    width: '100%',
                    maxWidth: '180px',
                    fontSize: '2.0rem',
                }}
            >
                Integrate into Existing
            </Typography>
        </Box>
        <Typography variant="h5" >
            We will fit in nicely.
        </Typography>
    </Grid>
}